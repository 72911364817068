/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Desi Karunia Sari
==============================================================================================*/
@import "sass-lib.scss";
@media (min-width: 0px) and (max-width: 1204px) {
	.wrapper-large,
	.wrapper-medium,
	.wrapper-small {
		width: 100vw;
		margin: 0 auto;
		max-width: 100%;
	}
	header {
		.wrapper-large {
			.top {
				display: none;
			}
			ul {
				&.menu {
					/*margin-right: 220px;*/
					li {
						a {
							padding: 10px 10px;
						}
						&.search-btn {
							input {
								outline: none;
								&[type="search"] {
									background: url(../images/material/search.png) no-repeat 4px
										center;
									border: none;
									padding: 12px 10px 10px 32px;
									width: 20px;
									border-radius: 5px;
									-webkit-transition: all 0.5s;
									-moz-transition: all 0.5s;
									transition: all 0.5s;
									cursor: pointer;
									position: absolute;
									right: 10px;
									position: relative;
									font-size: 16px;
									&:focus {
										width: 600px;
										background: #fff url(../images/material/search-active.png)
											no-repeat 4px center;
										border-color: #333;
										-webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
										-moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
										box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
										right: 580px;
									}
								}
								&:-moz-placeholder {
									color: transparent;
								}
							}
						}
					}
				}
			}
		}
		&.active {
			.wrapper-large {
				ul {
					&.menu {
						li {
							a {
								padding: 10px 7px;
							}
							&.search-btn {
								input {
									outline: none;
									&[type="search"] {
										background: url(../images/material/search-active.png)
											no-repeat 4px center;
										border: none;
										padding: 12px 10px 10px 32px;
										width: 20px;
										border-radius: 5px;
										-webkit-transition: all 0.5s;
										-moz-transition: all 0.5s;
										transition: all 0.5s;
										cursor: pointer;
										position: absolute;
										right: 10px;
										position: relative;
										font-size: 16px;
										&:focus {
											width: 600px;
											background: #fff url(../images/material/search-active.png)
												no-repeat 4px center;
											border-color: #333;
											-webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
											-moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
											box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
											right: 580px;
										}
									}
									&:-moz-placeholder {
										color: transparent;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.landing {
		.box {
			.index-img {
				.desc-landing {
					width: 28.4vw;
				}
			}
		}
	}
	.homepage {
		.main-slider {
			.list {
				height: auto;
				.slider-img {
					height: auto;
					width: 100%;
				}
				&.slick-active img {
					animation: unset;
				}
			}
			.slick-dots {
				li {
					button {
						left: 850px;
					}
				}
			}
			.content {
				width: 70%;
				h1 {
					line-height: 55px;
				}
			}
		}
	}
	.latest-news {
		.box-latest-news {
			.box-news {
				padding: 30px;
				width: 33.3%;
				margin-top: 0;
				margin-bottom: 20px;
			}
			.box-news-title {
				width: 100%;
				padding: 50px 0px 0px 30px;
				min-height: 150px;
			}
		}
	}
	.about-us {
		border-left: 0;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background: #0083c3;
			width: 170px;
			z-index: -1;
		}
		.content {
			.img-content {
				margin-left: 0%;
				img {
					width: 455px;
				}
			}
			.desc-content {
				width: 50%;
				padding-bottom: 0px;
				margin: 0;
				float: right;
			}
		}
	}
	.investing {
		border-left: 0;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background: #0083c3;
			width: 170px;
			z-index: -1;
		}
		.content {
			display: block;
			.img-content {
				margin-left: 0%;
				img {
					width: 455px;
				}
			}
			.desc-content {
				width: 50%;
				padding-bottom: 0px;
				margin: 0;
				float: right;
			}
		}
	}
	.static-pages {
		.main-banner {
			height: auto;
		}
	}
	.search-result {
		width: auto;
		padding: 0 30px;
	}
	.mid-content {
		padding: 100px 30px;
		@include boxSizing(border-box);
		&.detail-profile {
			.middle {
				.content-profile {
					.content-left {
						padding-right: 50px;
						.image-cover {
							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
		.middle {
			.box-content {
				width: 28%;
			}
			.box-project {
				.image-project {
					.content {
						padding: 0;
						bottom: 20px;
						left: 15px;
						width: auto;
						p {
							margin: 0;
							font-size: 16px;
						}
						h3 {
							font-size: 32px;
						}
					}
					&:hover img {
						filter: brightness(50%);
					}
					&:hover .content {
						background: none;
					}
				}
			}
		}
		&.news-list {
			padding: 0 30px;
			@include boxSizing(border-box);
		}
		&.visionmission {
			padding: 60px 30px;
			.content-left {
				width: 40%;
			}
			.content-right {
				width: 60%;
				margin-left: -50px;
				.content,
				.title {
					width: 65%;
				}
			}
		}
		&.history {
			padding: 100px 0;
			.content-left {
				padding: 0 30px;
				width: 50%;
				@include boxSizing(border-box);
				.image-cover {
					img {
						width: 100%;
					}
				}
			}
			.content-right {
				width: 50%;
				padding-right: 30px;
				@include boxSizing(border-box);
			}
			.history-nav {
				width: 100vw;
				.item {
					p {
						text-align: center;
					}
				}
			}
		}
		&#management {
			.wrapper-medium {
				width: 1050px;
				.highlight-text {
					font-size: 31px;
				}
				.content-left {
					.headline-text {
						font-size: 33px;
					}
				}
			}
		}
		&.detail-profile {
			.wrapper-medium {
				width: 900px;
				.content-left {
					width: 40%;
					.image-cover {
						img {
							width: 35vw;
						}
					}
				}
			}
		}
		&.box-project-sec {
			width: 100vw;
			padding: 100px 0;
			.middle {
				.box-project {
					.image-project {
						width: 46%;
					}
				}
			}
		}
		&.news-sec {
			.wrapper-medium {
				.wrapper-medium {
					width: 100vw;
					margin: 0;
					.news-slide {
						width: auto;
						.news {
							width: 22.3vw;
							margin-right: 8px;
						}
						.slick-list {
							width: 94vw;
						}
						.nav-right {
							right: 100px;
						}
						.nav-left {
							right: 250px;
						}
					}
				}
			}
		}
	}
	.pagingInfo {
		right: 125px;
	}
	.box-profile {
		width: 100vw;
	}
	&.image-grid {
		width: 967px;
	}
	.box-video {
		width: 100vw;
		left: 0px;
		.video-project {
			height: 700px;
			.item {
				width: 100vw;
				height: 560px;
				.thumb {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				.desc-project {
					width: 920px;
					left: 25px;
					padding: 30px;
					bottom: -15px;
					.description {
						margin-top: 0px;
					}
					.info-text {
						margin-top: 20px;
					}
				}
			}
			.video-slider {
				.item {
					.cover-thumb {
						top: 26%;
						left: 0;
						right: 0;
						text-align: center;
						p {
							font-size: 23px;
						}
						img {
							margin-bottom: 15px;
						}
					}
				}
				iframe {
					width: 100%;
					height: 575px;
				}
			}
		}
		.slick-arrow {
			&.nav-left {
				bottom: 225px;
				right: 150px;
			}
			&.nav-right {
				bottom: 225px;
				right: 80px;
			}
		}
	}
	.menu-id {
		background-color: transparent;
		border: none;
		// padding: 20px 30px;
		padding: 0;
		transform: none !important;

		&.float {
			ul {
				padding: 20px 30px;
			}
		}

		ul {
			padding: 20px 30px;

			li {
				width: 100%;
				z-index: 9;
				border-width: 0px 1px 1px 1px;
				border-style: solid;
				border-color: #ccc;
				padding: 10px 15px;
				@include boxSizing(border-box);
				&:not(.active) {
					float: none;
					width: 100%;
					display: none;
					background: #fff;
					a {
						padding: 0 210px 0 0;
						display: table-row;
					}
				}

				&.active-section {
					display: list-item;
					cursor: pointer;
					float: none;
					background: url(../images/material/down-active.png) no-repeat 99% 12px;
					display: block;
					text-align: center;
					border-top: 1px solid #ccc;
				}

				&.dropdown {
					padding: 0;
					position: relative;
					> .button-arrow {
						display: block;
						position: absolute;
						width: 32px;
						height: 22px;
						background: url(../images/material/down-disabled.png) no-repeat
							center center;
						top: 8px;
						right: 8px;
						z-index: 20;
					}
					a {
						display: inline-block;
						padding: 10px 15px;

						&::after {
							display: none;
						}
					}
					.sub-dropdown {
						position: relative;
						max-width: 100%;
						top: unset;
						left: unset;
						transform: none;
						background: transparent;
						box-shadow: none;
						visibility: visible;
						opacity: 1;
						padding: 0;
						display: none;

						ul {
							width: 100%;
							margin: 0 auto;
							li {
								border-width: 1px 0px 0px 0px;
								padding: 0;

								a {
									padding: 10px 15px 10px 30px;
									font-weight: normal;
									text-transform: uppercase;
								}
							}
						}
					}
				}
			}
		}
		/*.img-selmob{ position: absolute;top: 55px; right: 50px; }*/
	}
	.csr-slider {
		.slick-dots {
			li {
				button {
					bottom: 30px;
					left: 850px;
				}
			}
		}
	}
	.box-contact {
		.wrapper-medium {
			width: 100vw;
			padding: 0px 30px;
			@include boxSizing(border-box);
			.form {
				.field {
					input[type="text"],
					input[type="email"],
					textarea {
						width: 72vw;
					}
					input[type="submit"] {
						padding: 15px 5px;
						width: 150px;
					}
				}
			}
		}
	}
	.overlay {
		position: fixed;
		background: rgba(0, 0, 0, 0.6);
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 3;
		opacity: 0;
		display: none;
	}
	.homepage-project {
		p {
			font-weight: lighter;
			color: #999;
		}
		a {
			text-align: right;
		}
		.box-40 {
			width: 40% !important;
		}
		.box-20 {
			width: 20% !important;
		}
		.middle {
			.box-content {
				width: 100%;
				p {
					padding: 0;
				}
				.text-direct {
					margin: 0;
				}
			}
		}
	}
	.static-page {
		overflow: hidden;
	}
	.profile-slider {
		width: 100%;
		margin-left: 10px;
		.nav-right {
			position: absolute;
			right: 0px;
			top: 80px;
		}
		.nav-left {
			position: absolute;
			left: -20px;
			top: 80px;
		}
	}
	.modal .modal-content {
		left: 0;
		margin: 0 auto;
		margin-left: auto;
		right: 0;
		width: 75%;
	}
	.modal .close {
		right: -60px;
	}
	.modal iframe,
	.modal video {
		height: 420px;
		width: 100%;
	}
	#ytplayer {
		height: 365px;
		width: 100%;
		position: relative;
		left: -10%;
	}
	.box-video.details .video-project .vid_web iframe {
		width: 100%;
		height: 576px;
	}
	footer {
		height: auto;
		.info {
			margin-left: 0;
			width: 43%;
			.address {
				margin-left: 20px;
				width: calc(100% - 116px - 20px);
			}
		}
		.menu-footer {
			margin-right: 0;
			padding-left: 10px;
			width: calc(57%);
			ul {
				li {
					padding-left: 10px;
				}
			}
		}
	}
	.box-video.details {
		margin: 0;
		margin: auto;
		.video-project {
			&.detail-project {
				margin: 0;
				height: auto;
			}
			.video-slider {
				.item {
					height: auto;
					overflow: hidden;
					border-radius: 5px;
					.thumb {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						z-index: 1;
						object-position: center;
						height: 576px;
					}
					.cover-thumb {
						z-index: 2;
						top: 35%;
					}
					.desc-project-detail {
						bottom: 30px;
						display: block;
						z-index: 2;
					}
				}
			}
			.vid_web {
				height: 576px;
				video {
					height: 576px;
				}
			}
		}
	}
	.menu-id {
		&.float {
			position: relative;
			top: 0 !important;
			background: #fff;
		}
		ul {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			overflow: auto;
			padding-bottom: 20px;
			li {
				flex: none;
				a {
					font-size: 13px;
					padding-bottom: 0;
					&.active {
						border-bottom: 0;
					}

					> .sub-dropdown {
						display: none;
					}
				}
			}
			&::-webkit-scrollbar {
				height: 4px;
				opacity: 0;
			}
			&::-webkit-scrollbar-thumb {
				background: #888;
			}
			&::-webkit-scrollbar-track {
				background: #f1f1f1;
			}
		}
	}
}

@media all and (max-width: 1183px) {
	header {
		.wrapper-large {
			.logo {
				img {
					width: 220px;
				}
			}
			ul.menu {
				li {
					a {
						padding: 10px 5px;
					}
					&.search-btn {
						display: block;
						padding-right: 20px;
					}
					&.lang {
						display: block;
						a {
							padding: 10px 6px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 991px) {
	.wrapper-large,
	.wrapper-medium,
	.wrapper-small {
		width: 100%;
		margin: 0 auto;
		max-width: 100%;
	}
	.cmap {
		height: 375px;
	}
	.news-detail .middle p img {
		width: 100%;
		height: auto;
	}
	header {
		/*overflow-x: hidden;*/
		.panel {
			width: 410px;
			float: right;
			height: calc(100vh - 70px);
			position: absolute;
			right: -500px;
			background: #0083c3;
			top: 70px;
			z-index: 2;
			padding: 30px;
			opacity: 0;
			display: none;
			box-sizing: border-box;
			.close-menu {
				position: absolute;
				display: inline-block;
				width: 30px;
				height: 30px;
				margin: 25px;
				right: 100%;
				top: 10px;
				span {
					margin: 0 auto;
					position: relative;
					margin-top: 0;
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
					&:before,
					&:after {
						position: absolute;
						content: "";
						background: url(../images/material/close.png) no-repeat;
						width: 32px;
						height: 32px;
					}
				}
			}
		}
		.wrapper-large {
			padding: 15px 80px 0px 20px;

			ul {
				&.menu {
					li {
						> .sub-dropdown {
							ul {
								padding: 0;

								li {
									&.dropdown {
										.sub-dropdown {
											right: unset;
											transform: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.burger-slide {
			padding: 20px;
			width: 45px;
			float: left;
			color: #000;
			text-decoration: none;
			position: absolute;
			left: 90%;
			display: block;
			@include boxSizing(border-box);
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform(translate(-50%, -50%));
			}
			.white {
				display: block;
			}
			.active {
				display: none;
			}
		}
		&.act {
			overflow: hidden;
			position: fixed;
			height: 100%;
			width: 100%;
			.wrapper-large {
				ul {
					&.menu {
						li {
							&.lang {
								opacity: 1;
								right: 0;
							}
							&.search-btn {
								opacity: 1;
								right: 0;
							}
						}
					}
				}
			}
		}
		&.active {
			.panel {
				top: 70px;
			}
			.wrapper-large {
				padding: 15px 80px 0px 20px;
				.logo {
					margin-left: 0;
				}
				ul {
					&.menu {
						overflow-y: auto;
						overflow-x: hidden;
						margin-top: 0;
						li {
							// margin-bottom: 30px;width: 200px;
							a {
								font-size: 16px;
								color: #fff;
								margin: 0;
								padding: 10px 0;
							}
							&.lang {
								width: 410px;
								a {
									padding: 10px 6px;
								}
							}
							&.search-btn {
								width: 410px;
								border-right: none;
								padding: 0 30px;
								padding-right: 30px;
								cursor: pointer;
								img {
									width: 20px;
									margin-top: 0px;
								}
								img.active {
									display: none;
								}
								img.white {
									display: inline;
								}
								/*input{display: none}     */
								input {
									outline: none;
									&[type="search"] {
										width: 200px;
										background: url(../images/material/search-mob.png) no-repeat
											4px center;
										border-color: #fff;
										right: 2px;
										border: none;
										-webkit-transition: all 0.5s;
										-moz-transition: all 0.5s;
										transition: all 0.5s;
										cursor: pointer;
										font-size: 16px;
										color: #fff;
										padding: 12px 10px 10px 40px;
										&::-webkit-input-placeholder {
											/* Chrome/Opera/Safari */
											color: #fff;
										}
										&::-moz-placeholder {
											/* Firefox 19+ */
											color: #fff;
										}
										&:-ms-input-placeholder {
											/* IE 10+ */
											color: #fff;
										}
										&:-moz-placeholder {
											/* Firefox 18- */
											color: #fff;
										}
										&:focus {
											width: 200px;
											background: url(../images/material/search-mob.png)
												no-repeat 4px center;
											border-color: #fff;
											right: 2px;
											border: none;
											border-bottom: 1px solid #fff;
										}
									}
									&:-moz-placeholder {
										color: transparent;
									}
								}
							}
							&.lang {
								color: #fff;
								a {
									&.active {
										color: #fff;
									}
								}
							}
						}
					}
				}
				.burger-slide {
					padding: 20px;
					width: 45px;
					float: left;
					color: #000;
					text-decoration: none;
					position: absolute;
					left: 90%;
					display: block;
					.white {
						display: none;
					}
					.active {
						display: block;
					}
				}
			}
		}
		.wrapper-large {
			ul {
				&.menu {
					margin-right: 15px;
					overflow-y: auto;
					overflow-x: hidden;
					height: calc(100% - 250px);
					&::-webkit-scrollbar {
						width: 9px;
						border-radius: 2px;
					}
					&::-webkit-scrollbar-track {
						background: #e1f2fa;
					}
					&::-webkit-scrollbar-thumb {
						background: #212121;
					}
					li {
						display: block;
						margin-bottom: 25px;
						width: 100%;
						box-sizing: border-box;
						a {
							font-size: 16px;
							line-height: normal;
							margin: 0;
							padding: 10px 0;
						}

						&.dropdown {
							> a {
								&::after {
									display: none;
								}
							}
						}
						// &.dropdown {
						// 	padding-right: 40px;
						// }
						&:hover {
							> .sub-dropdown {
								visibility: visible;
								opacity: 1;
								z-index: 2;
							}
						}
						> .sub-dropdown {
							position: relative;
							top: 0;
							left: 0;
							transform: none;
							width: 100%;
							background: none;
							box-shadow: none;
							padding: 10px 0 10px 20px;
							visibility: visible;
							opacity: 1;
							z-index: 2;
							display: none;
							transition: none;
							ul {
								max-height: none;
								overflow: hidden;
								li {
									margin: 0;
									a {
										color: #fff;
										font-weight: 400;
										&:hover {
											color: #fff;
										}
									}
								}
							}
						}
						&.act {
							> .sub-dropdown {
								display: block;
							}
						}
						.button-arrow {
							display: block;
							position: absolute;
							width: 32px;
							height: 22px;
							background: url("../images/material/down-fff.png") no-repeat
								center center;
							top: 0;
							right: 0;
							z-index: 3;
						}
						&.lang {
							position: fixed;
							width: 410px;
							bottom: 120px;
							box-sizing: border-box;
							padding: 0 30px;
							margin: 0;
							transition: all 0.5s ease-out;
							right: -500px;
							opacity: 0;
							a {
								&:first-child {
									padding-left: 0;
									margin-left: 0;
								}
							}
						}
						&.search-btn {
							position: fixed;
							width: 410px;
							border-right: none;
							padding: 10px;
							padding-right: 30px;
							cursor: pointer;
							bottom: 162px;
							padding: 0 30px;
							margin: 0;
							transition: all 0.5s ease-out;
							right: -500px;
							opacity: 0;
							img {
								width: 20px;
								margin-top: 0px;
							}
							img.active {
								display: none;
							}
							img.white {
								display: inline;
							}
							/*input{display: none}     */
							input {
								outline: none;
								&[type="search"] {
									width: 200px;
									background: url(../images/material/search-mob.png) no-repeat
										4px center;
									border-color: #fff;
									right: 2px;
									border: none;
									-webkit-transition: all 0.5s;
									-moz-transition: all 0.5s;
									transition: all 0.5s;
									cursor: pointer;
									font-size: 16px;
									color: #fff;
									padding: 12px 10px 10px 40px;
									&::-webkit-input-placeholder {
										/* Chrome/Opera/Safari */
										color: #fff;
									}
									&::-moz-placeholder {
										/* Firefox 19+ */
										color: #fff;
									}
									&:-ms-input-placeholder {
										/* IE 10+ */
										color: #fff;
									}
									&:-moz-placeholder {
										/* Firefox 18- */
										color: #fff;
									}
									&:focus {
										width: 200px;
										background: url(../images/material/search-mob.png) no-repeat
											4px center;
										border-color: #fff;
										right: 2px;
										border: none;
										border-bottom: 1px solid #fff;
									}
								}
								&:-moz-placeholder {
									color: transparent;
								}
							}
						}
					}
				}
			}
		}
	}
	.landing {
		.highlight-text {
			display: none;
		}
		.box {
			.index-img {
				float: none;
				height: 340px;
				.desc-landing {
					width: 100vw;
					padding-top: 105px;
					position: absolute;
					top: 0;
					&:hover {
						background-color: transparent;
					}
					& > img {
						width: 50px;
					}
				}
				.img-landing {
					width: 100vw;
					.bg {
						height: auto;
						width: 100%;
					}
				}
			}
		}
	}
	.latest-news {
		.box-latest-news {
			.box-news-title {
				padding: 45px 0 35px 20px;
				min-height: unset;
				width: 100%;
			}
			.box-news {
				padding-top: 0;
				width: 33.33%;
				min-height: 250px;
				margin-top: 0;
				.box-title {
					min-height: 60px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
				}
			}
		}
	}
	.homepage {
		.main-slider {
			.list {
				height: auto;
				.slider-img {
					height: auto;
					width: 100%;
				}
				&.slick-active img {
					animation: unset;
				}
			}
			.content {
				width: 80%;
				h1 {
					font-size: 40px;
					line-height: unset;
				}
				p {
					font-size: 19px;
					line-height: 27px;
				}
			}
			.slick-dots li button {
				left: 600px;
			}
		}
	}
	.about-us {
		padding: 115px 20px;
		overflow: hidden;
		@include boxSizing(border-box);
		.content {
			.img-content {
				position: relative;
				left: auto;
				margin-left: 0;
				width: 46.15%;
				img {
					width: 100%;
				}
			}
			.desc-content {
				margin-left: 0;
				width: 53%;
				@include boxSizing(border-box);
				.logo-content {
					width: 120px;
				}
				.highlight-text {
					font-size: 23px;
					line-height: 30px;
					width: auto;
				}
				.description {
					font-size: 16px;
					line-height: 24px;
					width: auto;
				}
			}
		}
	}
	.investing {
		padding: 115px 20px;
		overflow: hidden;
		@include boxSizing(border-box);
		.content {
			display: block;
			.img-content {
				position: relative;
				left: auto;
				margin-left: 0;
				width: 46.15%;
				img {
					width: 100%;
				}
			}
			.desc-content {
				margin-left: 0;
				width: 53%;
				@include boxSizing(border-box);
				.logo-content {
					width: 120px;
				}
				.highlight-text {
					font-size: 23px;
					line-height: 30px;
					width: auto;
				}
				.description {
					font-size: 16px;
					line-height: 24px;
					width: auto;
				}
			}
		}
	}
	.mid-content {
		padding: 45px 30px;
		@include boxSizing(border-box);
		.wrapper-small {
			width: 700px;
		}
		.middle {
			.box-content {
				h3 {
					font-size: 23px;
					line-height: 33px;
				}
				p {
					font-size: 16px;
					line-height: 24px;
				}
			}
			.info {
				font-size: 23px;
				line-height: 30px;
			}
			.list-profile {
				.profile {
					width: 350px;
					padding-right: 0;
					.name {
						font-size: 19px;
						line-height: 30px;
					}
				}
			}
			.highlight-text {
				font-size: 23px;
				line-height: 33px;
			}
			p {
				font-size: 16px;
				line-height: 30px;
			}
			.name-project {
				font-size: 33px;
			}
		}
		&.history {
			.history-nav {
				.slick-slider {
					.slick-list {
						width: auto;
					}
				}
			}
		}
		&.visionmission {
			.content-right {
				width: 50%;
				.content {
					width: 100%;
				}
			}
			.content-left {
				width: 50%;
			}
		}
		&#management {
			.wrapper-medium {
				width: auto;
				.content-left {
					.headline-text {
						font-size: 23px;
						line-height: 33px;
					}
				}
				.content-right {
					.description-text {
						font-size: 16px;
						line-height: 32px;
					}
				}
			}
		}
		&.wrapper-small {
			padding: 45px 0;
			.middle {
				width: auto;
				margin: 30px;
				.highlight-text {
					font-size: 23px;
					line-height: 30px;
				}
				.description {
					font-size: 16px;
					line-height: 27px;
				}
				.box-detail {
					.detail-project {
						width: 45.9%;
					}
				}
				.img-responsive {
					img {
						width: 100%;
					}
				}
			}
		}
		&.box-project-sec {
			.middle {
				.box-project {
					.image-project {
						max-height: 195px;
					}
				}
			}
		}
		&.news-sec {
			.wrapper-medium {
				.wrapper-medium {
					.news-slide {
						.news {
							width: 44.7vw;
							padding-top: 0px;
							.name {
								font-size: 19px;
							}
						}
					}
				}
			}
		}
	}
	.pagingInfo {
		bottom: 100px;
	}
	.image-grid {
		width: 700px;
	}
	.profile-slider {
		width: 90%;
		margin-left: 30px;
		.nav-right {
			position: absolute;
			right: -30px;
			top: 80px;
		}
		.nav-left {
			position: absolute;
			left: -30px;
			top: 80px;
		}
	}
	.box-video {
		margin-bottom: 50px;
		.video-project {
			.video-slider {
				iframe {
					width: 100%;
					height: 432px;
				}
			}
			.item {
				.desc-project {
					width: 85vw;
					left: 0;
					.info-text {
						width: 36vw;
						h2 {
							font-size: 23px;
						}
					}
					.numb {
						font-size: 16px;
					}
				}
				.desc-project-detail {
					.numb {
						width: 78%;
					}
				}
			}
		}
		.slick-arrow.nav-left {
			bottom: 210px;
		}
		.slick-arrow.nav-right {
			bottom: 210px;
		}
	}
	.box-contact {
		.wrapper-small {
			width: 100vw;
			padding: 0 30px;
			box-sizing: border-box;
			.headline-text {
				font-size: 25px;
			}
		}
		.wrapper-medium {
			.fl {
				width: 100%;
				float: none;
			}
			.fr {
				float: none;
			}
			.form {
				.field {
					&.fr {
						margin-top: -70px;
						float: right;
					}
				}
			}
			.address {
				left: 0;
				top: -50px;
			}
		}
	}
	.csr-slider {
		.slick-dots {
			li {
				button {
					bottom: 30px;
					left: 600px;
				}
			}
		}
	}
	.modal {
		.modal-content {
			top: 150px;
		}
		.close {
			top: 0;
		}
	}
	.mid-content.news-list {
		margin-top: 30px;
	}
	.box-video .video-project .item .desc-project .description {
		height: 100px;
	}
	.box-video .video-project .item {
		height: 490px;
	}
	/*.video-project .video-slider .item .desc-project-detail{ bottom: 290px; }*/
	.box-video .video-project {
		height: 600px;
	}
	/*.box-video.details .slick-arrow.nav-left{ bottom: 140px; }
    .box-video.details .slick-arrow.nav-right{ bottom: 140px; }*/
	.box-video .video-project .item .thumb {
		height: 88%;
	}
	.vid_web video {
		position: absolute;
		bottom: auto;
		top: 0;
		height: 430px;
	}
	.box-video.details {
		margin: 0;
		margin: auto;
		.video-project {
			&.detail-project {
				margin: 0;
				height: auto;
			}
			.video-slider {
				.item {
					height: auto;
					overflow: hidden;
					border-radius: 5px;
					.thumb {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						z-index: 1;
						object-position: center;
						height: 432px;
					}
					.cover-thumb {
						z-index: 2;
						top: 35%;
					}
					.desc-project-detail {
						bottom: 30px;
						display: block;
						z-index: 2;
					}
				}
			}
			.vid_web {
				height: 432px;
				video {
					height: 432px;
				}
			}
		}
	}
	#ytplayer {
		height: 338px;
		width: 200%;
		position: relative;
		left: -10%;
	}
	.drive-viewer-video-preview-img {
		height: auto;
		width: 100%;
	}
	.drive-viewer-video-play-button {
		top: 30%;
	}
	.box-video.details .video-project .vid_web iframe {
		width: 100%;
		height: 432px;
	}
	footer {
		align-items: flex-end;
		box-sizing: border-box;
		height: auto;
		min-height: 302px;
		padding: 40px;
		&:after {
			clear: both;
			display: block;
			content: " ";
		}
		.info {
			width: 36%;
			flex-wrap: wrap;
			> img {
				margin-top: 0;
			}
			.address {
				margin: 20px 0 0 0;
				width: 100%;
				p {
					font-size: 14px;
				}
			}
		}
		.menu-footer {
			box-sizing: border-box;
			margin: 0;
			padding-left: 30px;
			padding-bottom: 30px;
			width: 64%;
			ul li {
				margin-bottom: 20px;
				padding-left: 15px;
			}
			p {
				font-size: 12px;
				text-align: right;
			}
		}
	}
	.listing-download {
		margin: 40px 0px;
		.list {
			flex: unset;
			max-width: 100%;
			padding: 0;
			width: 100%;
		}
	}
	.listing-report {
		.list {
			flex: 0 0 50%;
			max-width: 50%;
			figure {
				img {
					width: 100%;
				}
			}
		}
	}

	.wrap-statement {
		.filter {
			flex-flow: row wrap;
			label {
				flex: 1 0 100%;
				max-width: 100%;
				margin-bottom: 25px;
			}
			.slider-filter {
				flex: 1 0 100%;
				max-width: 100%;
			}
		}
		.slider-download {
			margin-right: -25px;
			.list {
				align-items: flex-start;
			}
		}
		figure {
			flex: 0 0 90px;
			max-width: 90px;
		}
		.text {
			flex: 0 0 calc(100% - 90px);
			max-width: calc(100% - 90px);
		}
	}
}

@media (max-width: 1280px) {
	.wrap-table {
		overflow-x: scroll;
	}
	.highcharts-figure,
	.highcharts-data-table table {
		min-width: unset;
	}
}
